import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { getPageType } from 'cms/parsers';
import SiteContext from 'site/SiteContext';
import styled from 'styled-components';
import DefaultPage from './content_types/DefaultPage';
import { buildCrumbs } from 'helpers/breadcrumb';
import BreadCrumbWithRouter from 'components/organisms/BreadCrumbWithRouter';

import { buildJourneylineHeader } from 'helpers/journeylineheader';

import { Loading } from 'dma-ui-kit';
import PageNotFoundPage from '../PageNotFound';

import createAxiosInstance from 'helpers/axios';
const axiosInstance = createAxiosInstance();

const appEnv = process.env.REACT_APP_ENV;
const baseEndpoint = process.env.REACT_APP_CMS_BASE_PATH;

const NoBreadCrumb = styled.div`
    margin-bottom: 20px;
`;

const DrupalPage = (props) => {

    const { location } = props;
    const { languagePath, siteData } = useContext(SiteContext);

    const breadCrumbs = buildCrumbs(location.pathname);

    if (!location) return <></>;

    const pathName = (location.pathname == "/") ? siteData.front : location.pathname;
    const endpoint = `${baseEndpoint}${appEnv}/content${languagePath}${pathName}.json`;
    
    const { isLoading, error, data } = useQuery(['getEvent', languagePath], async () => 
        await axiosInstance.get(endpoint)
    );

    if (isLoading) return <Loading />;

    if (data.data) {
        const pageManifest = data.data;
        const PageType = getPageType(data);

        return (
            <div>
                {buildJourneylineHeader(pageManifest)}
                { breadCrumbs.length > 0 ? 
                    <BreadCrumbWithRouter breadcrumbs={breadCrumbs} /> : 
                    <NoBreadCrumb /> 
                }
                { error ? 
                    <DefaultPage /> :
                    <PageType pageManifest={pageManifest} />
                }
            </div>
        );
    }

    return <PageNotFoundPage />;
};

export default DrupalPage;
