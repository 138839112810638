import axios from 'axios';

// By default axios (annoyingly) retries requests on 4xx errors, which
// is not desireable for CDN data requests.

const createAxiosInstance = () => {
    const instance = axios.create({
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    });

    // Add a response interceptor
    instance.interceptors.response.use(
        (response) => {
            // Map response.data into response
            return response.data;
        },
        (error) => {
            // Avoid retries on errors by rejecting promise
            return Promise.reject(error);
        }    
    );

    return instance;
};

export default createAxiosInstance;