import React, {useContext} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import SiteContext from 'site/SiteContext';

import ObjectContainer from 'components/organisms/collection/ObjectContainer';
import { buildObjectManifestURL } from 'helpers/collection';

import BreadCrumbWithRouter from 'components/organisms/BreadCrumbWithRouter';
import { buildCrumbs } from 'helpers/breadcrumb';

import CollectionDisclaimer from 'components/organisms/paragraph/CollectionDisclaimer';

import { Loading } from 'dma-ui-kit';
import PageNotFoundPage from '../PageNotFound';

import createAxiosInstance from 'helpers/axios';
const axiosInstance = createAxiosInstance();

const ObjectPage = ({  }) => {
    const { languagePath } = useContext(SiteContext);
    const { objectID } = useParams();

    const breadCrumbs = buildCrumbs(`/art/collection/object/${objectID}`);
    const endpoint = buildObjectManifestURL({ objectID });

    const { isLoading, error, data } = useQuery(['getSite', languagePath] , async () => 
        await axiosInstance.get(endpoint)
    );

    if (isLoading) return <Loading />;

    if (data.id)
        return (
            <>
                <BreadCrumbWithRouter breadcrumbs={breadCrumbs} />
                <ObjectContainer data={data} />
                <CollectionDisclaimer />
            </>
        );

    return <PageNotFoundPage />;
};

export default ObjectPage;
